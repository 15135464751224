import React from 'react'
import usePageQuery from '../../pageQuery'
import ScrollTo from 'src/components/ScrollTo'
import Img from 'gatsby-image'

import { Section } from './style'

const ComoFunciona = () => {
  const data = usePageQuery()

  return (
    <Section id='como-funciona'>
      <div className='container'>
        <div className='container-como-pedir-meu-cartao row justify-content-between align-items-center'>
          <div className='col-12 col-md-6 col-lg-5 mb-5 mb-md-0'>
            <Img fluid={data.bannerComoFuncionaOCartao.fluid} alt='Ilustração de um homem sorrindo.' />
          </div>
          <div className='col-12 col-md-6 col-lg-6'>
            <h2>
              Como funciona o Cartão Benefício INSS?
            </h2>
            <p>
              Depois de finalizar a contratação do Cartão Benefício INSS e passar pela análise de crédito,
              você receberá uma via dele na sua casa com um limite que poderá ser usado como preferir: para fazer
              compras no crédito ou sacar até 70% do valor em dinheiro, pagando em até 84x.
            </p>

            <p>As parcelas das suas compras ou saque serão descontadas em até 5% da sua pensão/benefício do INSS, mas,
              caso você ultrapasse esse valor, não precisa se preocupar, pois receberá uma fatura para pagamento.
            </p>

            <p>E lembrando que, se não utilizar o seu Cartão Benefício INSS, nenhum valor será cobrado de você. Além disso,
              os 5% da sua pensão/benefício destinados ao cartão não comprometem a sua margem de empréstimo consignável.
            </p>

            <ScrollTo
              to='#form'
              section='dobra_04'
              sectionName=' Como funciona o Cartão Benefício INSS?'
              elementName='Garanta o seu Cartão Benefício!'
            >
              <button
                title='Garanta o seu Cartão Benefício'
                className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center d-md-block mt-md-5'
              >
                Garanta o seu Cartão Benefício!
              </button>
            </ScrollTo>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default ComoFunciona
